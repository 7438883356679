@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUIBlack-Italic.woff2') format('woff2'),
        url('SegoeUIBlack-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI.woff2') format('woff2'),
        url('SegoeUI.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUIBlack.woff2') format('woff2'),
        url('SegoeUIBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Bold.woff2') format('woff2'),
        url('SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Italic.woff2') format('woff2'),
        url('SegoeUI-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-BoldItalic.woff2') format('woff2'),
        url('SegoeUI-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Light.woff2') format('woff2'),
        url('SegoeUI-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-LightItalic.woff2') format('woff2'),
        url('SegoeUI-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Semibold.woff2') format('woff2'),
        url('SegoeUI-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-SemiboldItalic.woff2') format('woff2'),
        url('SegoeUI-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI Semilight';
    src: url('SegoeUI-Semilight.woff2') format('woff2'),
        url('SegoeUI-Semilight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI Semilight';
    src: url('SegoeUI-SemilightItalic.woff2') format('woff2'),
        url('SegoeUI-SemilightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

