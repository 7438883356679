.btn-primary {
  background: $theme-gradient !important;
  border-radius: 20px;
  border: 0 !important;
  font-size: 12px;
  padding: 7px 38px;
  text-transform: uppercase;
  &:focus,
  &:active {
    box-shadow: none !important;
    border-color: none !important;
  }
}
.btn-outline-danger {
  border: 1px solid #ff0000 !important;
  border-radius: 20px;
  background: transparent !important;
  font-size: 12px;
  padding: 7px 38px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ff0000 !important;
  &:focus,
  &:active {
    box-shadow: none !important;
    border-color: none !important;
  }
}
.btn-outline-success {
  border: 1px solid #4fbf26 !important;
  border-radius: 20px;
  background: transparent !important;
  font-size: 12px;
  padding: 7px 38px;
  text-transform: uppercase;
  font-weight: 500;
  color: #4fbf26 !important;
  &:focus,
  &:active {
    box-shadow: none !important;
    border-color: none !important;
  }
}
.btn-outline-primary {
  border: 1px solid $theme !important;
  border-radius: 20px;
  background: transparent !important;
  font-size: 12px;
  color: $theme !important;
  padding: 7px 36px;
  text-transform: uppercase;
  &:focus,
  &:active {
    box-shadow: none !important;
    border-color: none !important;
  }
  &.select {
    background: $theme !important;
    color: white !important;
    img {
      filter: brightness(100);
    }
  }
}
.btn-outline-secondary {
  border: 1px solid $theme-black !important;
  border-radius: 20px;
  background: transparent !important;
  font-size: 12px;
  color: $theme-black !important;
  padding: 7px 36px;
  text-transform: uppercase;
}
.fs-5 {
  font-size: 16px !important;
  @media (max-width: 991px) {
    font-size: 14px !important;
  }
}

.input-group-text {
  background: transparent !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-3 {
  border-radius: 12px !important;
}
hr {
  background-color: #c5a1da !important;
}

.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__tile--active {
  background: $theme !important;
}
.react-calendar button {
  height: 50px !important;
  border-radius: 100%;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important;
}
.border-top {
  border-top: 1px solid #e5e5e5 !important;
}
.recently-added {
  color: black !important;
}
.sort {
  top: -8px;
  font-size: 10px;
}
