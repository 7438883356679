@media (max-width: 1440px) {
  .banner-col {
    padding: 30px 110px 40px;
  }
  .times-ends {
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
    li {
      margin-right: 5px;
    }
  }
}

@media (max-width: 1200px) {
  .banner-col {
    padding: 30px 65px 40px;
  }
  .header-logo {
    max-height: 40px;
  }
  .signup-form {
    padding-right: 0;
  }
  .w-70,
  .w-75 {
    width: 100% !important;
  }
  .profile {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .banner-col {
    padding: 30px 0;
  }
  .banner:before,
  .banner::after {
    width: 100%;
    height: 50%;
  }
  .banner::after {
    top: 50%;
  }
  .para-text {
    font-size: 16px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 26px;
  }
  .footer {
    .col {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      text-align: center;
      margin: 10px 0;
      &:first-child,
      &:last-child {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &:last-child {
        margin-top: 0;
      }
    }
  }
  .navbar-collapse {
    button,
    .nav-item {
      width: 100%;
    }
  }

  .navbar-light .navbar-toggler {
    padding: 0px 3px;
  }
  .navbar-light .navbar-toggler-icon {
    background-size: 20px;
  }
  .notification-menu {
    right: -92px !important;
    transform: none !important;
    left: initial !important;
  }
  .everlens-slider .slick-list {
    padding-left: 20%;
  }
  .signup-form {
    padding-left: 30px;
  }
  .s-logo {
    left: 43px;
  }
  .bid-profile {
    width: 100%;
  }
  .nav-tabss .nav-pills .nav-link.fs-6 {
    font-size: 14px !important;
  }
  .profile-name {
    font-size: 18px;
  }
  .profile-subname {
    font-size: 14px;
  }
  .nav-pills {
    margin-top: 20px;
  }
  .share-icon {
    height: 32px;
    padding: 0;
  }
  .share-icon i {
    font-size: 12px !important;
  }
  .big-circle {
    width: 10px;
    height: 10px;
  }
  .forward-arrow:before {
    left: 0;
    top: 14px;
    transform: rotate(90deg);
  }
  .my-wallet {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .para-text {
    font-size: 14px;
  }
  .banner-slider .slick-slide {
    padding: 20px 8px 38px;
  }
  .banner-col {
    padding: 30px 0;
  }
  h2 {
    font-size: 24px;
  }

  .header-logo {
    max-height: 35px;
  }
  .profile-dropdown {
    h6 {
      font-size: 14px;
    }
  }
  h5 {
    font-size: 18px;
  }
  .everlens-slider .slick-list {
    padding-left: 34%;
  }
  .auth-page:after {
    display: none;
  }
  .signup-form {
    padding-left: 0;
  }
  .s-logo {
    position: relative;
    left: initial;
  }
  .bid-profile {
    height: 300px;
  }
  .post-content {
    max-width: 100%;
  }
  .time-auticon:after {
    display: none;
  }
}
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  .footer {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: left;
      &:last-child {
        margin-top: 10px;
      }
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  .header-logo {
    max-height: 30px;
  }
  .profile-dropdown {
    width: 205px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  .notification-menu {
    width: 272px;
  }
  .everlens-slider .slick-list {
    padding-left: 0;
    margin-left: 0;
  }
  .owner-name {
    &.owners {
      width: 100%;
      margin: 5px 0;
      border-left: 0 !important;
      border-bottom: 1px solid #dddcdc;
      padding-bottom: 10px;
    }
  }
  .big-circle {
    width: 5px;
    height: 5px;
  }
}
