@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-calendar/dist/Calendar.css";
@import "./variables";
@import "./common";
@import "./overrides";
@import "./layout";
@import "./style";
@import "./responsive";
@import "./pages";
@import "./../fonts/stylesheet.css";

main {
  flex-grow: 1;
}

body {
  color: $theme-black;
}
iframe {
  display: none;
}

.button-custom-outline-hover{
  &:hover{
      background-color: $theme !important;
      color: #fff !important;
  }
}