$theme: #b24bf3;
$black: #000;
$theme-black: #212529;
$gray: #777777;
$lightGray: #fafafa;
$mediumGray: #e5e5e5;
$theme-gradient: linear-gradient(102deg, #bf75ee 0%, #b34ff2 100%);
$black-txt: #2a2a2a;

// text variables

.text-theme {
  color: $theme !important;
}
.text-gray {
  color: $gray;
}
.text-light-gray {
  color: $lightGray;
}
.text-medium-gray {
  color: $mediumGray;
}
.text-black {
  color: $black;
}

// bg vatiable

.bg-theme {
  background: $theme !important;
}
.bg-medium-gray {
  background: $mediumGray !important;
}
.bg-theme-light {
  background: #f4e2ff !important;
}
