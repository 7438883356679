body {
    font-family: "General Sans";
    margin: 0;
    padding: 0;
    color: $black;
}

header {
    box-shadow: 0px 4px 8px #0000000f;
    position: relative;
    z-index: 99;
    padding: 10px 0;
}

.header-logo {
    height: 42px;
}

.search-input {
    border: 1px solid #e5e5e5;
    border-radius: 21px;
    width: 37%;
    padding-left: 3.5rem;
}

.banner-slider {
    transform: scale(0.9);
    margin-top: -10px;
    min-height: 491px;

    .slick-slide {
        // padding: 32px 0px 38px;
        padding: 32px 0px 30px;

        .live-box {
            transform: scaleY(0.9);
            transition: 0.5s;
            opacity: 0.6;
        }
    }

    .slick-slide.slick-active.slick-center.slick-current .live-box {
        transform: scale(1.08);
        opacity: 1;
        position: relative;
        z-index: 1;
    }
}

.card-body {
    padding: 15px;
}

.nft-image {
    width: 100%;
    height: 245px;
    object-fit: cover;
    display: block;
}

.banner-col {
    padding: 30px 125px 40px;

    &.first {
        margin-left: -15px;
    }

    &.second {
        margin-right: -12px;
    }

    .explore-btn {
        margin-top: -15px;
    }
}

.top-text {
    padding-left: 20px;
}

.live-text {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.s-icon {
    width: 26px;
    margin: 0 auto;
}

.share-dropdown {
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    min-width: auto;
    // &.share {
    //     left: 25% !important;
    // }
}

.ss-imge {
    width: 100%;
    height: 250px;
    border-radius: 20px;
    margin-bottom: 20px;

    img,
    video {
        border-radius: 20px;
    }
}

.btn-close {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.para-text {
    font-size: 18px;
}

.upload-steps {
    display: flex;
}

.step-text h2 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
}

.bid-image {
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
}

.large-bid-image {
    height: 90vh;
    object-fit: cover;
    border-radius: 0;
}

.step-text {
    margin-left: 10px;
}

.footer {
    background-color: $lightGray;

    a {
        font-weight: 400;
        font-size: 13px;
    }

    .social-icon {
        img {
            width: 16px;
        }
    }
}

ul {
    list-style: none;
}

.banner {

    &:before,
    &::after {
        content: "";
        width: 50%;
        height: 100%;
        background: url(../images/home/banner_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: -8px 0px 20px #00000014;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}

.live-auctions .Create-btn {
    height: 40px;
}

.text-time {
    right: 20px;
    bottom: 20px !important;
    padding: 4px 10px;
    border-radius: 8px;
}

.create-content {
    .fix-wrapper {

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            background: #000;
            color: #fff !important;
        }
    }
}

.title-line {
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #e5e5e5;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.auth-page:after {
    content: "";
    background: url(../images/home/sign_up_img.png);
    width: 50%;
    position: fixed;
    right: 0;
    height: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #b399d4;
}

.s-logo {
    top: 40px;
    left: 112px;

    @media (max-width: 1439px) {
        left: 82px;
    }
}

.signup-form {
    padding: 130px 100px 30px 100px;

    @media (max-width: 1439px) {
        padding: 130px 60px 30px;
    }

    a {
        text-decoration: underline;
    }
}

.trending-slider {
    display: flex;
    overflow: auto;
    justify-content: space-around;
    width: 100%;
    touch-action: auto;

    @media (max-width: 1200px) {
        justify-content: flex-start;
    }

    .t-name {
        border: 1px solid #eeeeee;
        border-radius: 10px;
        padding: 7px 10px;
        font-weight: 500;
        background: #fafafa;
        font-size: 10px;
        margin-right: 5px;
        height: 31px;

        img {
            width: 14px;
        }

        &.selected {
            background: $theme;
            border: 1px solid $theme;
            color: #fff;
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
        display: none;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #b0b0b0;
    }
}

.notification-btn {
    border: 1px solid $gray !important;
    border-radius: 100%;
    color: $gray !important;
    width: 35px;
    height: 35px;

    &.show {
        border: 1px solid $theme !important;
        color: $theme !important;
    }
}

.notification-menu {
    li {
        &:not(:last-child) {
            border-bottom: 1px solid #f4e2ff;
        }

        .dropdown-item {
            &:hover {
                background: #f4e2ff;
            }
        }
    }
}

.profile-dropdown {
    width: 270px;

    a {
        &:hover {
            color: $theme !important;
        }
    }
}

.bid-profile {
    width: 500px;
    height: 500px;
    border-radius: 20px;
}

.info-name {
    min-width: 145px;
}

.dot-icon {
    &.br-rounded {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.share-icon {
    border-radius: 0;
    height: 38px;

    &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &.br-rounded {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.time-auticon {
    &::after {
        content: "";
        height: 100%;
        width: 1px;
        background: #f4e2ff;
        position: absolute;
        top: 0;
        right: 0px;
    }
}

.c-dropdown {
    border: 0;
    border-left: 1px solid #f4e2ff;
}

.my-wallet {
    width: 80%;
    padding-top: 40px;
}

.forward-arrow {
    &:before {
        content: "";
        position: absolute;
        left: -20px;
        background-image: url(../images/home/transaction.svg);
        background-repeat: no-repeat;
        top: 57%;
        width: 20px;
        height: 20px;
        background-size: 20px;
    }
}

.wallet-list {
    padding: 15px 18px;

    &:not(:last-child) {
        border-bottom: 1px solid #f4e2ff;
    }

    &:hover,
    &.latest {
        box-shadow: 0px 3px 6px #b24bf314;
        border-radius: 6px;
    }
}

.uploaded-image {
    width: 200px;
    height: 220px;
}

.tags {
    background: #f4e2ff;
    border-radius: 6px;
    padding: 6px 8px;
    display: inline-block;
}

.calendar {
    font-family: "General Sans";
    box-shadow: 0px 3px 6px #0000000f;
    border: 2px solid #f4e2ff;
    border-radius: 10px;
}

.owner-tab {
    &:not(:first-child) {
        border-top: 1px solid #e9e9e9;
    }

    &:first-child {
        padding-top: 0 !important;
    }
}

.multiple {
    &:before {
        content: "";
        width: calc(100% - 19px);
        height: 100%;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.175);
        bottom: -5px;
        left: 50%;
        border-radius: 10px;
        z-index: -1;
        margin: 0 auto;
        transform: translateX(-50%);
        background: white;
    }

    &:after {
        content: "";
        width: calc(100% - 34px);
        height: 100%;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.175);
        bottom: -10px;
        left: 50%;
        border-radius: 10px;
        z-index: -2;
        transform: translateX(-50%);
    }
}

.banner-slider .slick-slide.slick-active.slick-center.slick-current .slider-box.multiple::before {
    bottom: -24px;
    height: 100%;
}

.banner-slider .slick-slide.slick-active.slick-center.slick-current .slider-box.multiple::after {
    bottom: -29px;
    height: 100%;
}

.slider-box {
    position: relative;

    &.multiple {
        &:before {
            content: "" !important;
            width: calc(100% - 19px);
            height: calc(100% - 42px);
            position: absolute;
            border: 1px solid rgba(0, 0, 0, 0.175);
            bottom: 18px;
            left: 50%;
            border-radius: 10px;
            z-index: -1;
            margin: 0 auto;
            transform: translateX(-50%);
            background: white;
        }

        &:after {
            content: "" !important;
            width: calc(100% - 34px);
            height: calc(100% - 42px);
            position: absolute;
            border: 1px solid rgba(0, 0, 0, 0.175);
            bottom: 13px;
            left: 50%;
            border-radius: 10px;
            z-index: -2;
            transform: translateX(-50%);
            background: white;
        }
    }
}

.slick-slider.banner-slider {
    .live-box.multiple {

        &:before,
        &:after {
            display: none;
        }
    }
}

.everlens-slider {
    .slick-slide {
        padding-bottom: 10px;
    }
}

.multiple-preview {
    position: absolute;

    @for $i from 1 through 100 {
        &:nth-child(#{$i + 1}) {
            top: #{$i * 5}px;
            z-index: -#{$i * 2};
            // transform: scale(calc(-#{$i * 0.9}));
        }
    }
}

.notification-menu {
    width: 330px;
}

.col-circle-1,
.col-circle-2 {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.col-circle-1 {
    background: transparent linear-gradient(146deg, #3d52dc 0%, #be1c8f 100%) 0% 0% no-repeat padding-box;
}

.col-circle-2 {
    background: transparent linear-gradient(146deg, #3db5fb 0%, #cb31f1 100%) 0% 0% no-repeat padding-box;
}

.sort-text {
    min-width: 140px;
    display: inline-block;
}

.upload-player {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    border-radius: 12px;

    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }
}

.cross {
    color: white !important;
    text-shadow: 0 0 6px black;
    z-index: 1;
}

.nft-player {

    video,
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.e-search-wrapper {
    position: absolute;
    background: #fff;
    height: auto;
    padding-bottom: 10px;
    box-shadow: 0 0 6px 0 #ccc;
    border-radius: 10px;
    margin-top: 10px;

    @media (max-width: 991px) {
        margin-top: 3px;
    }

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #b4b4b4;
    }

    .comment-wrapper {
        .nav-tabs {
            padding-left: 0;
            padding-right: 0;

            &:after {
                content: none;
            }

            .nav-link {
                border: 0;
                padding: 5px 0;
                flex-grow: 1;
                font-size: 14px;
                color: gray;
                font-weight: 500;

                &:first-child {
                    border-top-left-radius: 20px;
                }

                &:last-child {
                    border-top-right-radius: 20px;
                }

                &.active {
                    color: #b24bf3;
                    border: 0;
                    border-bottom: 2px solid #b24bf3 !important;
                }
            }
        }
    }

    div#nav-tabContent {
        max-height: 350px;
        overflow: auto;
    }
}

.s-list {
    &:not(:last-child) {
        border-bottom: 1px solid #f2f2f2;
    }
}

.report-modal .modal-dialog {
    max-width: 400px;
    margin: 0 auto;
}

.follower-list {
    &:not(:last-child) {
        border-bottom: 1px solid #dfdfdf;
    }
}

.followers-list-wrap {
    // padding: 20px 25px;
    max-height: 440px;
    overflow: auto;
    padding-right: 5px;
    padding-left: 5px;
}

.f-profile {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

.collection-box {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 20px;

    button {
        min-width: 210px;
    }
}

.collections {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0px 20px 40px #ffffff0a;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent linear-gradient(180deg, #ffffff00 0%, #000000a8 100%);
        border-radius: 20px;
    }

    .col-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }

    .col-name {
        color: white;
        z-index: 1;
        position: absolute;
        bottom: 5px;
        left: 0;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 18px;
        font-weight: 600;
    }
}

.col-upload-img {
    width: 70px;
    height: 70px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
    }
}

.base-url-text {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    font-weight: 400;
}

.url-input {
    padding-left: 115px;
}

.collection-modal {
    .modal-dialog {
        max-width: 370px;
    }

    textarea {
        min-height: 100px;
    }

    .title {
        font-size: 20px;
        letter-spacing: normal;
    }
}

.import-modal {
    .modal-dialog {
        max-width: 330px;
    }

    .subtext {
        max-width: 225px;
        margin: 0 auto;
    }

    .title {
        font-size: 20px;
        letter-spacing: normal;
    }
}

.cover-img {
    width: 100%;

    height: 230px;

    border-radius: 10px;
    object-fit: cover;
}

.collection-profile {
    margin-top: -90px;

    .profile-image {
        box-shadow: none !important;
        outline: none;

        img {
            box-shadow: none !important;
            outline: none;
        }
    }
}

.profile-id {
    background: #e5e5e5;
    border-radius: 18px;
    padding: 8px 11px;
    display: inline-block;
    font-size: 12px;
    max-width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 8px;
}

.item-box {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}

.stats-content {
    border: 1px solid #f4e2ff;
    border-radius: 6px;
    padding: 20px;

    h4 {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: bold;
    }
}

.name-text {
    p {
        word-break: break-all;
    }
}

.col-box {
    width: 160px;
    min-height: 130px;
    border-radius: 6px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #f4e2ff !important;
    padding: 10px !important;
    text-align: center;

    &.active {
        border: 1px solid #b24bf3 !important;
    }

    i {
        font-size: 35px;
        margin-bottom: 6px;
    }

    .c-img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 6px;
    }
}

.delete-menu {
    max-width: 144px;
    min-width: 78px;
    width: 100%;
}

.w-35 {
    width: 35px;
    margin-bottom: 6px;
}

.empty-content {
    width: 100%;
    min-height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-file {
    border-radius: 8px;
    text-align: center;
    padding: 20px;
}

.dashed-border {
    border: 2px dashed #f4e2ff;
}

.file-uploaded {
    padding: 20px;
    text-align: center;

    img {
        width: 126px;
        height: 80px;
        object-fit: cover;
    }
}

.profile-tabs-row {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
    padding-top: 15px;
}

.tiktok-info {
    ul {
        list-style: disc;
    }
}

.otp-inputs {
    width: 60px;
    height: 50px;
    border-radius: 0;
    margin: 0 10px;
    text-align: center;
}

.m-icons {
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-icon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid red;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 6px;
    cursor: pointer;
    background: white;
}

.center-loader {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lottie-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


