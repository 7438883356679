// create post section starts

.title-col h2 {
  color: #2a2a2a;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.create-contact-wrapper {
  max-width: 550px;
  margin: 60px auto 100px;
  .create-inner {
    .create-fieldstyle:not(:last-child) {
      margin-bottom: 25px;
    }
  }
  .create-fieldstyle {
    label {
      display: block;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    textarea {
      min-height: 100px;
    }
    input,
    textarea {
      border: 0;
      border-bottom: 1px solid #f4e2ff;
      height: auto;
      padding: 6px 10px;
      border-radius: 0 !important;
      transition: 0.3s;
      &:focus {
        padding: 6px 10px !important;
        border-radius: 4px !important;
        border: 1px solid #f4e2ff;
        border-color: #b24bf3 !important;
        box-shadow: none !important;
      }
      &::placeholder {
        color: #a8a8a8;
        font-size: 16px;
      }
    }
    .upload-col {
      text-align: center;
      border: 2px dashed #b24bf3;
      padding: 25px;
      width: 100%;
      border-radius: 8px;
      img {
        width: 25px;
        margin: 0 auto;
        display: block;
      }
      span {
        color: $black;
        font-size: 15px;
        line-height: 22px;
        margin: 15px 0;
        display: block;
      }
      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        padding: 0 10px 10px;
        margin: 0 auto;
      }
      .upload-btn-wrapper input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
    .eth-col {
      position: relative;
      .eth-div {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        background: transparent linear-gradient(109deg, #8b93b4 0%, #626890 100%);
        padding: 3px 12px;
        color: #fff;
        font-size: 11px;
        text-align: center;
        border-radius: 17px;
      }
    }
  }
}

.term-condition {
  p {
    word-break: break-all;
    margin-bottom: 2rem;
    line-height: 28px;
    strong {
      font-weight: 600;
    }
  }
}
.left-space,
.left-to-space {
  padding-left: 15px;
}

.meta-how {
  padding: 20px 0 0;
  ul li {
    line-height: 30px;
    font-size: 16px;
    margin-bottom: 5px;
    list-style: none;
  }
  span {
    font-weight: 600;
  }
  strong {
    font-weight: 600;
  }
}

.meta-mask {
  padding: 20px 0 40px;
  a {
    color: $theme !important;
  }
}

.meta-mask h2 {
  color: #2a2a2a;
  margin: 0;
  font-size: 18px;
  line-height: 40px;
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
  font-weight: 600;
}
.mini-heading span {
  font-size: 19px;
  font-weight: 600;
}
.meta-mask p {
  line-height: 30px;
}

.meta-mask h3 {
  font-size: 16px;
  padding-bottom: 10px;
  line-height: 28px;
}

.meta-image {
  margin: 25px 0;
}

.right-img img {
  width: 100%;
}

.banner-section {
  background-image: url("../images/bg_image.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  // margin-top: 80px;
  padding: 30px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph-img h3 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-bottom: 30px;
}

.banner-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.right-img img {
  width: 100%;
  max-width: 450px;
}

.banner-text h2 {
  line-height: 40px;
  padding-bottom: 20px;
}

.banner-text h2 span {
  color: #c077ee;
}

.banner-text button.btn.btn-large.white-paper {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 6px;
  background: #375bd2;
  width: 248px;
  letter-spacing: 0.16px;
  height: 40px;
}

.banner-text button.btn.btn-large.white-paper:hover {
  background: transparent;
  color: #b24bf3 !important;
  border-color: #b24bf3 !important;
}

.image-txt {
  background: #fcfcfc;
  padding: 50px 0;
}

.main-contents {
  width: 100%;
  max-width: 70%;
  margin: 0px auto;
  padding: 40px 0;
}

.image-txt-left img {
  width: 100%;
  max-width: 270px;
}

.image-txt-right h3 {
  font-size: 20px;
  line-height: 28px;
  color: #2a2a2a;
}

.image-txt-right {
  align-items: center;
  display: flex;
  height: 100%;
}

.token-hading h2 {
  color: #2a2a2a;
  font-size: 28px;
  padding: 0 25px;
}

.native-token {
  margin: 80px 0 40px;
}

.token-hading ul {
  text-align: left;
}

.token-hading p {
  margin-top: 40px;
  color: #a020f0;
  font-size: 18px;
  padding: 20px 0 10px;
}

.token-hading ul li {
  padding: 10px 0px;
  display: flex;
  align-items: end;
}

.token-hading ul li img {
  margin-right: 12px;
}

.table-img {
  margin: 20px 0;
  box-shadow: 5px 10px 20px #0000000a;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  border-bottom: none;
}

tbody tr td img {
  max-width: 25px;
  margin-right: 10px;
}

.first-table thead {
  background: #c077ee;
  color: #fff;
}

.table-img img {
  width: 100%;
}

ul.inner-ul {
  padding-left: 30px !important;
}

.token-hading .table-img h2 {
  border-radius: 10px 10px 0px 0px;
  background: transparent linear-gradient(95deg, #c077ee 0%, #b24bf3 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 12px;
  font-size: 20px;
}

.table-img span {
  color: #2a2a2a;
  padding: 20px 0px;
  display: inline-block;
}

table.table th,
table.table td {
  border: 1px solid #eee;
}

.secound-table {
  margin-top: 80px;
}

table.table {
  margin-bottom: 0;
}

.buy-side {
  text-align: left;
  box-shadow: 5px 10px 20px #0000000a;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  margin: 50px 0 30px;
}

.buy-side-text img {
  width: 100%;
  max-width: 35px;
  margin-right: 8px;
}

.token-hading .buy-side-text h2 {
  padding: 0;
  font-size: 30px;
}

.token-hading .buy-side-text p {
  margin: 0;
  padding: 0;
  color: #222;
  font-size: 16px;
}

.buy-side-text.border-top {
  margin-top: 30px;
  padding-top: 10px;
}

.buy-side-text h4 {
  color: #b24bf3;
  font-size: 18px;
  margin: 20px 0;
}

.safty-token {
  background: #b24bf3;
  padding: 40px 0;
}

.token-services h2,
.token-services p {
  color: #fff;
}

.token-services p {
  line-height: 28px;
  margin-top: 30px;
}

.graph-bottom {
  margin: 80px 0;
}

h2.suppy-heading {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.graph-bottom.token-hading span {
  width: 100%;
  text-align: center;
}

.graph-bottom.token-hading h2 {
  text-align: center;
  margin: 0;
}

.graph-img img {
  width: 100%;
}

.graph-bottom .table-img.table-responsive {
  width: 100%;
  max-width: 80%;
  margin: 0px auto 0;
}

.tele-paper {
  display: flex;
}

.banner-text .tele-paper a.btn.btn-large.white-paper {
  width: 30%;
  text-align: center;
  margin-right: 25px;
  font-size: 13px;
}

.graph-img svg {
  width: 100%;
  height: 100%;
}

.supply-table {
  display: flex;
  align-items: center;
}

.token-hading td p {
  padding: 0 !important;
  margin: 0 !important;
}

.token-hading .table-img p span.hide {
  opacity: 0;
  padding: 0 !important;
}

.table-img h6.tax-mechanism {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
}

.tele-paperss {
  width: 348px;
  text-align: center;
  margin-top: 30px;
}
.tele-paperss a.btn.btn-large.white-paper {
  width: 177px;
  font-size: 13px;
}
.comman {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.meta-image.text-center.no-one img.img-fluid {
  display: block;
  margin: 0 auto 30px;
}

@media (max-width: 992px) {
  .banner-text h2 br {
    display: none;
  }
  .banner-text h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .main-contents {
    max-width: 100%;
  }
  .graph-bottom .table-img.table-responsive {
    max-width: 100%;
    margin: 0px auto 0;
  }
  .banner-text .tele-paper a.btn.btn-large.white-paper {
    width: 35%;
  }
}

@media (max-width: 767px) {
  .image-txt-right h3 {
    font-size: 16px;
    line-height: 27px;
  }
  .token-hading tr td p img {
    display: none;
  }
  .token-hading .table-img p span.hide {
    display: none;
  }
  .graph-bottom .table-img.table-responsive {
    margin-top: 60px;
  }
  .main-contents {
    padding: 20px 0;
  }
  .image-txt-left img {
    max-width: 180px;
  }
  .native-token {
    margin: 50px 0;
  }
  .token-hading .table-img h2 {
    padding: 8px;
    font-size: 16px;
  }
  .table-img span br {
    display: none;
  }
  table.table th,
  table.table td {
    font-size: 12px;
  }
  .token-services h2 {
    font-size: 24px;
  }
  .graph-bottom .col.col-md-12,
  .graph-bottom .col.col-md-6 {
    flex: 0 0 100%;
  }
  .banner-text .tele-paper a.btn.btn-large.white-paper {
    width: 50%;
  }
  .main-contents .col.col-md-6 {
    flex: 0 0 100%;
    text-align: center !important;
    padding-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .token-hading h2 {
    font-size: 20px;
    padding: 0 0;
    line-height: 30px;
  }
  .banner-section .col.col-md-6 {
    flex: 0 0 100%;
  }
  // .banner-section {
  //   margin-top: 75px;
  //   padding: 60px 0 30px;
  // }
  .right-img img {
    max-width: 280px;
  }
  .banner-section .col.col-md-6.text-end {
    text-align: center !important;
  }
  .main-contents .col.col-md-6 {
    flex: 0 0 100%;
    text-align: center !important;
    padding-bottom: 40px;
  }
  .mobile-reverse .row {
    flex-direction: column-reverse;
  }
  .image-txt {
    padding: 50px 0 0;
  }
}
