.text-theme {
  color: $theme;
}
p,
label,
.form-control {
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 13px;
  }
}
.container {
  @media (min-width: 1200px) {
    max-width: 1260px;
  }
}
a {
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

.title {
  letter-spacing: 1.2px;
  font-weight: bold;
  margin-bottom: 40px;
}
.btn {
  outline: 0 !important;
}
.btn-icon {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  outline: 0;
}
.w-37 {
  width: 37%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.fw-medium {
  font-weight: 500 !important;
}
.fw-semi-bold {
  font-weight: 600 !important;
}
.form-group {
  margin-bottom: 16px;
}
.fs-18 {
  font-size: 18px !important;
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}
.fs-14 {
  font-size: 14px !important;
  @media (max-width: 767px) {
    font-size: 13px !important;
  }
}
.fs-11 {
  font-size: 11px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.small-user {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  object-fit: cover;
}
.user {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 100%;
  object-fit: cover;
}
.large-user {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  object-fit: cover;
  @media (max-width: 1400px) {
    width: 40px;
    height: 40px;
  }
}
.nav-select {
  .nav-link {
    color: #6c757d;
    padding: 3px 13px;
    font-weight: 500;
    border: 2px solid transparent !important;
    border-radius: 0;
    &.active {
      color: $theme;
      border-bottom-color: $theme !important;
    }
  }
}
.slick-slide {
  padding: 0 8px;
}
.card {
  border-radius: 20px;
}
.big-font-38 {
  font-size: 30px;
  @media (max-width: 1200px) {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
.big-font-45 {
  font-size: 36px;
  @media (max-width: 1200px) {
    font-size: 34px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
}
.section-spacing {
  padding: 80px 0;
  @media (max-width: 991px) {
    padding: 60px 0;
  }
  @media (max-width: 767px) {
    padding: 50px 0;
  }
}
.text-gradient {
  background-image: linear-gradient(90deg, #8c4ff1 0%, #ec60dc 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.input-group-text {
  height: 32px;
}
.form-control,
.form-select {
  color: black;
  font-weight: 500;
  height: 32px;
  line-height: normal;
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}
.form-select {
  color: $gray;
  font-weight: 400;
}

textarea {
  &.caption {
    height: auto;
  }
}
::-webkit-input-placeholder {
  font-weight: 400;
}

:-ms-input-placeholder {
  font-weight: 400;
}

::placeholder {
  font-weight: 400;
}
.modal-content {
  border-radius: 10px;
}

.common-modal {
  .modal-dialog {
    @media (min-width: 575px) {
      max-width: 580px;
    }
  }
  &.steps-modal {
    .modal-dialog {
      @media (min-width: 575px) {
        max-width: 420px;
      }
    }
  }
  &.tiktok-modal {
    .modal-dialog {
      @media (min-width: 575px) {
        max-width: 388px;
      }
    }
  }

  .btn-close {
    position: absolute;
    background-size: 10px;
    opacity: 1;
    right: 8px;
    top: 8px;
    z-index: 10;
  }
  .modal-content {
    border-radius: 16px;
  }
  .modal-body {
    padding: 40px;
  }
}
.colorbox-modal {
  max-width: 95%;
  .close-icon {
    background: #fff;
    border-radius: 100%;
    right: -10px;
    top: -10px;
    box-shadow: 0 0 3px 0 #000 !important;
  }
}
.square-box {
  border: 1px solid #f4e2ff;
  border-radius: 6px;
  height: 210px;
}
.w-40 {
  width: 40px;
}
.everlens-slider {
  .slick-list {
    padding-left: 14%;
    margin-left: -10px;
  }
  &.space {
    .slick-list {
      padding-left: 0;
    }
  }
}
.common-dropdown {
  box-shadow: 0px 10px 30px #0000001a;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 10px !important;
  padding: 15px 8px;
  .dropdown-item {
    font-size: 12px;
    color: black;
    &.added {
      background: #fcf8ff !important;
    }
  }
  .icon {
    width: 14px;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background: #fcf8ff !important;
  }
  &.filters {
    max-width: 250px;
  }
}
.s-space.search-input {
  padding-left: 40px;
}
.n-count {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: $theme;
  font-size: 10px;
  right: -2px;
}
.object-fit-cover {
  object-fit: cover;
}
.border-left {
  border-left: 1px solid #dddcdc;
}
.icon-top {
  position: relative;
  top: -3px;
}
.nav-tabss {
  .nav-pills .nav-link {
    border-bottom: 1px solid #fff;
    border-radius: 0;
    text-align: center;
    &.active {
      color: $theme !important;
      border-bottom: 1px solid $theme;
    }
  }
}
.border-pink {
  border: 1px solid #f4e2ff !important;
}
.common-table {
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 10px;
  tr {
    th {
      font-weight: 400;
      border-bottom-color: $theme !important;
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
    td,
    th {
      padding: 10px 20px;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
}
.option-select {
  border-radius: 30px;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid $theme;
  color: $theme;
  font-weight: 500;
  outline: 0 !important;
}
.profile-image {
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}
.collection-image {
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
}
.disable {
  opacity: 0.5 !important;
}
.big-circle {
  width: 15px;
  height: 15px;
}
.square-profile {
  width: 70px;
  height: 70px;
}
.w-70 {
  width: 70% !important;
}
.w-85 {
  width: 85%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.post-content {
  max-width: 85%;
  margin: 0 auto;
}
.profile {
  width: 70%;
}
.border-dashed {
  border: 2px solid #f4e2ff;
}
.preview-box-content {
  padding: 26px 40px 40px;
  box-shadow: 0px 3px 6px #0000001a;
}
.preview-box {
  min-height: 485px;
}
.z-index-1 {
  z-index: 1;
}

.common-tabs {
  .nav-link {
    border: 1px solid $theme !important;
    border-radius: 20px;
    background: transparent !important;
    font-size: 12px;
    color: $theme !important;
    padding: 6px 36px;
    text-transform: uppercase;
    font-weight: 500;
    &.active {
      background: linear-gradient(102deg, #bf75ee 0%, #b34ff2 100%) !important;
      color: white !important;
      font-weight: 400;
    }
  }
}

.token-id {
  word-break: break-all;
}

.comment {
  background: white;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 14px;
  padding: 3px 10px;
}
.fs-6 {
  font-size: 14px !important;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-truncate-1 {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
// .text-trim {
//   max-width: 50px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }
.available-count {
  max-width: 100px;
  @media (max-width: 1200px) {
    max-width: 70px;
  }
}
.name-text {
  .text-truncate {
    @media (max-width: 1200px) {
      max-width: 60px;
    }
  }
}
.border-1 {
  border: 1px solid #f4e2ff !important;
}
.insta-big {
  width: 25px;
}
.insta-small {
  width: 16px;
}
.insta-signup {
  width: 51px;
}
.cursor-auto {
  cursor: auto !important;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #b34ff2;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loader-modal {
  max-width: 295px;
  .modal-body {
    padding: 50px 0;
  }
}
